
<template>
  <div class="page-container">
    <div class="section">
      <!--搜索栏-->
      <div class="control-box">
        <div class="cb-left">
          <el-input
            v-model.trim="TableParams.name"
            placeholder="分类名称"
            style="width: 200px;margin-right:20px"
            clearable
            class="control-item"
            @keyup.enter.native="getTechnicalSpportList"
          />
          <span>状态：</span>
          <el-select
            v-model="TableParams.state"
            clearable
            placeholder="请选择"
            style="width: 200px;margin-right:20px"
          >
            <el-option
              v-for="item in searchState"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-button
            class="control-item"
            type="primary"
            icon="el-icon-search"
            @click="getTechnicalSpportList"
          >
            搜索
          </el-button>
        </div>

        <div class="cb-right">
          <el-button
            class="control-item"
            style="margin-left: 10px;"
            type="primary"
            icon="el-icon-edit"
            @click="ToCreate"
          >
            添加
          </el-button>
        </div>
      </div>

      <div class="table-box">
        <el-table :data="tableData" border fit highlight-current-row style="width: 100%;">
          <el-table-column label="分类名称" show-overflow-tooltip>
            <template slot-scope="{ row }">
              <span>{{ row.title }}</span>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" align="center" width="120">
            <template slot-scope="{ row }">
              <span>{{ row.dCreateDate }}</span>
            </template>
          </el-table-column>

          <el-table-column
            label="操作"
            align="left"
            width="300"
            class-name="small-padding fixed-width"
          >
            <template slot-scope="{ row }">
              <el-button
                :type="row.shelves === '2' ? 'success' : 'danger'"
                size="mini"
                @click.stop="handleSwitch(row)"
              >
                {{ row.shelves === '2' ? '启用' : '禁用' }}
              </el-button>
              <el-button size="mini" type="primary" @click="handleEdit(row)">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination-box">
<!--        <Pagination-->
<!--          v-show="totalCount > 0"-->
<!--          :total="totalCount"-->
<!--          :page.sync="TableParams.page"-->
<!--          :limit.sync="TableParams.count"-->
<!--          @pagination="getTechnicalSpportList"-->
<!--        />-->
      </div>
    </div>
    <el-dialog
      :title="editForm.id ? '编辑' : '添加'"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :modal-append-to-body="false"
      :append-to-body="false"
      :close-on-click-modal="false"
    >
      <div class="form-box">
        <el-form
          ref="editForm"
          :model="editForm"
          :rules="updateRules"
          label-position="left"
          label-width="100px"
        >
          <el-form-item label="分类名称" prop="classification">
            <el-input
              v-model.trim="editForm.classification"
              maxlength="30"
              show-word-limit
              clearable
            />
          </el-form-item>
        </el-form>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="handleSubmit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from '@/api'

export default {
  data() {
    return {
      TableParams: {
        name: '',
        type: '',
        state: '',
        page: 1,
        count: 10,
      },
      editForm: {
        classification: ''
      },
      dialogVisible: false,
      updateRules: {
        classification: [{ required: true, trigger: 'change', message: '请输入章节名称' }]
      },
      searchType: [
        {
          value: 1,
          label: '富文本',
        },
        {
          value: 2,
          label: 'PDF',
        },
        {
          value: 3,
          label: '视频',
        },
      ],
      searchState: [
        {
          value: 1,
          label: '已上架',
        },
        {
          value: 2,
          label: '未上架',
        },
      ],
      tableData: [],
      totalCount: 0,
    };
  },
  created() {
    this.getTechnicalSpportList();
  },
  methods: {
    getTechnicalSpportList() {
      API.technicalSpportList(this.TableParams).then((res) => {
        this.tableData = res.message.data.list;
        this.totalCount = res.message.data.totalCount;
      });
    },
    handleSwitch(row) {
      if (row.shelves === '2') {
        this.$confirm('确定要将当前技术支持文档上架吗？', '提示', {
          closeOnClickModal: false,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then((res) => {
          API.update({ state: 1 }, row.id).then((res) => {
            console.log(res);
            this.$message({
              message: '上架成功',
              type: 'success',
            });
            this.getTechnicalSpportList();
          });
        });
      } else if (row.shelves === '1') {
        this.$confirm('确定要将当前技术支持文档下架吗？', '提示', {
          closeOnClickModal: false,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then((res) => {
          API.update({ state: 2 }, row.id).then((r) => {
            this.$message({
              message: '下架成功',
              type: 'success',
            });
            this.getTechnicalSpportList();
          });
        });
      }
    },
    ToCreate() {
      this.editForm = {};
      this.dialogVisible = true;
      this.$nextTick(() => {
        // 清除验证
        this.$refs['editForm'].clearValidate();
      });
    },
    handleSubmit() {
      const that = this;
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          // 编辑
          if (that.editForm.id && that.editForm.id !== '') {
            // API.addSupportClass(that.editForm).then(() => {
            //   that.$message({
            //     message: '编辑成功',
            //     type: 'success'
            //   });
            //   that.dialogVisible = false;
            //   that.getList();
            // });
          } else {
            // 新增
            API.addSupportClass({ ...that.editForm }).then(() => {
              that.$message({
                message: '创建成功',
                type: 'success'
              });
              that.dialogVisible = false;
              that.getList();
            });
          }
        }
      });
    },
    handleClose(done) {
      this.editForm = {};
      this.$refs.editForm.resetFields(); // 重置为初始值并移除校验结果
      done();
    },
    handleEdit(row) {
      this.editForm = { ...row };
      this.dialogVisible = true;
    },
  },
};
</script>

<style></style>
